<template>
  <b-row :class="{ 'mt-5 p-3 ' : $mq === 'sm' || $mq === 'xs' || $mq === 'md', 'p-5' : $mq === 'lg' || $mq === 'xl'}" class="mt-2 new-post"  v-if="!showLoading">
    <div class="loading" v-if="showLoading">
      <b-spinner style="width: 5rem; height: 5rem; margin: auto;" variant="primary"></b-spinner>
    </div>
    <b-col cols="12" sm="12" md="12" lg="10" xl="10" align="center">
      <legend>{{ post.titulo }}</legend>
    </b-col>
    <b-col cols="12" sm="12" md="12" lg="10" xl="10" class="p-2">
      <b-img :src="post.foto" fluid/>
    </b-col>
    <b-col cols="12" sm="12" md="12" lg="10" xl="10" class="p-2"  align="center">
      <div class="texto" v-html="post.texto">
      </div>
    </b-col>
    <b-col cols="12" sm="12" md="12" lg="10" xl="10" v-if="posts.length > 0">
      <title-news title="VEJA TAMBÉM"/>
    </b-col>
    <b-col cols="12" sm="12" md="6" lg="3" xl="3" class="pt-2" v-for="(post_more, index) in posts" :key="index">
      <template>
       <card-new 
        v-if="posts.length > 0 && index <= 2"
        :id="post_more.id"
        :slug="post_more.slug"
        :img="post_more.foto" 
        :legend="post_more.titulo"
        />
      </template>
    </b-col>
  </b-row>
</template>

<script>
import TitleNews from '../components/TitleNews.vue'
import CardNew from '../components/CardNew.vue'
import { collection, getDocs } from "firebase/firestore"
export default {
  name: 'blog',
  components:{
      TitleNews,
      CardNew
  },
  data(){
    return{
      post: null,
      posts: null,
      showLoading: false,
    }
  },
  computed:{
    imgWebp(){
      return this.canUseWebP()
    }
  },
  methods: {
    canUseWebP() {
      var elem = document.createElement('canvas');
      if (!!(elem.getContext && elem.getContext('2d'))) return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0;
      return false;
    },
    dynamicSort(property) {
        let sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a,b) {
            let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    },
    async inicializar(){
      this.showLoading = true;
      if(!this.$store.getters.getAllPosts){
        let collectionRef = collection(this.$db, "Blog");
        let posts = await getDocs(collectionRef)
        let postsMap = await posts.docs.map(item=> item.data()).sort(this.dynamicSort("-id"))
        this.post = postsMap.filter(item => item.id === this.$route.params.id)[0]
        this.$store.dispatch('setCurrentPost', this.post)
        this.posts = postsMap.filter(item => item.id !== this.$route.params.id)
        this.$store.dispatch('setOthersPosts', this.posts)
      } else {
        this.$store.dispatch('setCurrentPost', this.$store.getters.getAllPosts.filter(item => item.id === this.$route.params.id))
        this.$store.dispatch('setOthersPosts', this.$store.getters.getAllPosts.filter(item => item.id !== this.$route.params.id))
        this.post = this.$store.getters.getCurrentPost[0]
        this.posts = this.$store.getters.getOthersPosts
      }
      this.showLoading = false;
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  },
  watch:{
    '$route.params.id':function (value){
      if(!this.showLoading) this.inicializar()
    } 
  },
  created(){
    this.inicializar()
    document.body.style.backgroundColor = "#0D245E"
    document.body.style.backgroundImage = this.$mq !== 'xs' 
                                          && this.$mq !== 'sm' 
                                          && this.$mq !== 'md' ? 
                                            !this.imgWebp ? "url('/img/BG_BLOG.jpg')" : "url('/img/BG_BLOG.webp')" : 
                                            !this.imgWebp ? "url('/img/sm/BG_BLOG.jpg')" : "url('/img/sm/BG_BLOG.webp')"
  }
}
</script>

<style lang="scss" scoped>
.new-post{
  a, a:active, a:hover {
  text-decoration: none;
  font-weight: bold;
  color: #ffffff;
  }
}
</style>
<style scoped>
legend{
  margin-bottom: 20px !important;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 30px;
  line-height: normal;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
}
img{
  width: 100%;
  background-color: #ffffff;
  padding: 1.5%;
}
.texto{
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  color: #ffffff;
  text-align: justify;
}
.titulo{
  margin-bottom: 10px !important;
}
.loading{
  top: 0;
  left: 0;
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>