<template>
    <div class="title">
        <h4><u>{{ inicias }}</u>{{ separacao }}</h4>
    </div>
</template>

<script>
export default {
  props: {
    title: String
  },
  data(){
      return {
          inicias: null,
          separacao: null,
      }
  },
  created(){
      this.inicias = this.title[0] + this.title[1] + this.title[2] + this.title[3]
      this.separacao = this.title.substring(4)
  }
}
</script>

<style scoped>
@font-face {font-family: "Front Page Neue";
    src: url("~@/assets/fonts/front-page-neue/6dc0bf90067240ac1860b77131d8144d.eot"); /* IE9*/
    src: url("~@/assets/fonts/front-page-neue/6dc0bf90067240ac1860b77131d8144d.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("~@/assets/fonts/front-page-neue/6dc0bf90067240ac1860b77131d8144d.woff2") format("woff2"), /* chrome firefox */
    url("~@/assets/fonts/front-page-neue/6dc0bf90067240ac1860b77131d8144d.woff") format("woff"), /* chrome firefox */
    url("~@/assets/fonts/front-page-neue/6dc0bf90067240ac1860b77131d8144d.ttf") format("truetype"), /* chrome firefox opera Safari, Android, iOS 4.2+*/
    url("~@/assets/fonts/front-page-neue/6dc0bf90067240ac1860b77131d8144d.svg#Front Page Neue") format("svg"); /* iOS 4.1- */
    font-display: swap !important;
}
h4{
    color: #ffffff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 22px;
    text-underline-offset: 20%;
    margin-top: 10px;
    margin-bottom: 0;
}
.title{
    width: 100%;
    display: block;
    text-align: left;
}
</style>